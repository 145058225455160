html,
body {
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
}

/* Scroll bar start */
* {
    scrollbar-width: thin;
    scrollbar-color: #e22837 white;
}

*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: white;
}

*::-webkit-scrollbar-thumb {
    background-color: #e22837;
}
/* Scroll bar end */
