.topDiv {
    background: url("../images/bg.jpg");
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.894);
    overflow: "hidden";
}

.aboutMeDiv {
    color: white;
    padding: 100px 30% 200px 50px;
    z-index: 1;
}

.heading {
    font-size: clamp(3.25rem, 6.25vw, 5.95rem);
    margin-bottom: 20px;
}

.info {
    font-size: clamp(1.15rem, 6.25vw, 1.2rem);
    margin-bottom: 20px;
}

.profileLink {
    float: left;
    margin: 0 2rem 0 0;
}

@media screen and (max-width: 800px) {
    .aboutMeDiv {
        padding: 100px 50px 200px 50px;
    }
}
