.topDiv {
    background-color: white;
    color: black;
    text-align: center;
}

.Heading {
    font-weight: 500;
    font-size: 4rem;
    padding-top: 50px;
}

.miniHeading {
    font-size: 1.5rem;
    margin-bottom: 4rem;
}
