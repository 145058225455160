@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
}

/* Scroll bar start */
* {
    scrollbar-width: thin;
    scrollbar-color: #e22837 white;
}

*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: white;
}

*::-webkit-scrollbar-thumb {
    background-color: #e22837;
}
/* Scroll bar end */

.landingArea_topDiv__1YM1A {
    background: url(/static/media/bg.9202fac8.jpg);
    background-position: top;
    background-repeat: no-repeat;
    /* background-size: cover; */
    position: relative;
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.894);
    color: white;
    height: 100vh;
}

.landingArea_topName__1nV1z {
    font-size: 1.8rem;
    font-weight: 400;
    position: relative;
    -webkit-animation: landingArea_moveTopName__VV2TZ 1s 1;
            animation: landingArea_moveTopName__VV2TZ 1s 1;
    -webkit-animation-delay: 0;
            animation-delay: 0;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
    float: left;
}

@-webkit-keyframes landingArea_moveTopName__VV2TZ {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes landingArea_moveTopName__VV2TZ {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.landingArea_NavIcon__1nuC6 {
    float: right;
}

.landingArea_landingPageNameDiv__3Iaxt {
    -webkit-flex: 16 1;
            flex: 16 1;
}

.landingArea_div1__32wHT {
    font-size: clamp(1.7rem, 6.25vw, 2rem);
    position: relative;
    -webkit-animation: landingArea_moveDiv1__278JP 1s 1;
            animation: landingArea_moveDiv1__278JP 1s 1;
    -webkit-animation-delay: 1.7s;
            animation-delay: 1.7s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

@-webkit-keyframes landingArea_moveDiv1__278JP {
    from {
        left: 20px;
        opacity: 0;
    }
    to {
        left: 0px;
        opacity: 1;
    }
}

@keyframes landingArea_moveDiv1__278JP {
    from {
        left: 20px;
        opacity: 0;
    }
    to {
        left: 0px;
        opacity: 1;
    }
}

.landingArea_div2__2kMYi {
    font-size: clamp(2.5rem, 5vw, 10rem);
    font-weight: 500;
    position: relative;
    -webkit-animation: landingArea_moveDiv2__1UN-3 1s 1;
            animation: landingArea_moveDiv2__1UN-3 1s 1;
    -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

@-webkit-keyframes landingArea_moveDiv2__1UN-3 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes landingArea_moveDiv2__1UN-3 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.landingArea_div3__20t_W {
    font-size: clamp(1.7rem, 6.25vw, 2rem);
    position: relative;
    -webkit-animation: landingArea_moveDiv3__3RbBi 1s 1;
            animation: landingArea_moveDiv3__3RbBi 1s 1;
    -webkit-animation-delay: 1.7s;
            animation-delay: 1.7s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

@-webkit-keyframes landingArea_moveDiv3__3RbBi {
    from {
        right: 20px;
        opacity: 0;
    }
    to {
        right: 0px;
        opacity: 1;
    }
}

@keyframes landingArea_moveDiv3__3RbBi {
    from {
        right: 20px;
        opacity: 0;
    }
    to {
        right: 0px;
        opacity: 1;
    }
}

.landingArea_profileButton__2KRjy {
    float: none;
    margin: 2rem 0;
}

.landingArea_moveProfileButton1__2xAXd {
    position: relative;
    -webkit-animation: landingArea_moveProfileButtons__2ZHKb 0.5s 1;
            animation: landingArea_moveProfileButtons__2ZHKb 0.5s 1;
    -webkit-animation-delay: 2.7s;
            animation-delay: 2.7s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

.landingArea_moveProfileButton2__2U7RS {
    position: relative;
    -webkit-animation: landingArea_moveProfileButtons__2ZHKb 0.5s 1;
            animation: landingArea_moveProfileButtons__2ZHKb 0.5s 1;
    -webkit-animation-delay: 2.8s;
            animation-delay: 2.8s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}
.landingArea_moveProfileButton3__1rmn6 {
    position: relative;
    -webkit-animation: landingArea_moveProfileButtons__2ZHKb 0.5s 1;
            animation: landingArea_moveProfileButtons__2ZHKb 0.5s 1;
    -webkit-animation-delay: 2.9s;
            animation-delay: 2.9s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}
.landingArea_moveProfileButton4__1MHIF {
    position: relative;
    -webkit-animation: landingArea_moveProfileButtons__2ZHKb 0.5s 1;
            animation: landingArea_moveProfileButtons__2ZHKb 0.5s 1;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

@-webkit-keyframes landingArea_moveProfileButtons__2ZHKb {
    from {
        left: 10px;
        opacity: 0;
    }
    to {
        left: 0px;
        opacity: 1;
    }
}

@keyframes landingArea_moveProfileButtons__2ZHKb {
    from {
        left: 10px;
        opacity: 0;
    }
    to {
        left: 0px;
        opacity: 1;
    }
}

@media screen and (max-width: 1280px) {
    .landingArea_profileButton__2KRjy {
        float: left;
        margin: 0 2rem 0 0;
    }
}

.Navbar_mobileNav__387ZV {
    /* display: none; */
    display: inline;
    display: initial;
}

.Navbar_wrapper__1jUkU {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    background: #141313;
    -webkit-clip-path: circle(5px at calc(100% - 45px) 45px);
            clip-path: circle(5px at calc(100% - 45px) 45px);
    transition: all 0.3s ease-in-out;
}
.Navbar_active__1wUqX:checked ~ .Navbar_wrapper__1jUkU {
    -webkit-clip-path: circle(75%);
            clip-path: circle(75%);
    z-index: 10;
}
.Navbar_menubtn__2r95q {
    position: relative;
    z-index: 15;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    font-size: 40px;
    color: #fff;
    cursor: pointer;
    /* background-color: #141313; */
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;
}
.Navbar_active__1wUqX:checked ~ .Navbar_menubtn__2r95q {
    /* background: #fff; */
    color: #d6372e;
    z-index: 100;
}
.Navbar_active__1wUqX:checked ~ .Navbar_menubtn__2r95q i:before {
    /* font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 40px; */
    content: "\f00d";
    z-index: 10;
}
.Navbar_wrapper__1jUkU ul {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    padding: 0;
}
.Navbar_wrapper__1jUkU ul li {
    margin: 15px 0;
}
.Navbar_wrapper__1jUkU ul li a {
    color: none;
    text-decoration: none;
    font-size: 30px;
    font-weight: 500;
    padding: 5px 30px;
    color: #fff;
    position: relative;
    line-height: 50px;
    transition: all 0.3s ease;
}
.Navbar_wrapper__1jUkU ul li a:after {
    position: absolute;
    content: "";
    background: #fff;
    width: 100%;
    height: 50px;
    left: 0;
    border-radius: 50px;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    z-index: 1;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}
.Navbar_wrapper__1jUkU ul li a:hover:after {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
}
.Navbar_wrapper__1jUkU ul li a:hover {
    color: #4158d0;
}

.Navbar_afterdot__nxKB8 {
    font-size: 1.8em;
    background-clip: content-box;
    font-style: normal;
    background: linear-gradient(45deg, #e22837, #df3d7e);
    background: -webkit-linear-gradient(45deg, #e22837, #df3d7e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 2px;
}

.Navbar_navLinksdiv__3J32N {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 30px;
    -webkit-transform: translate(-80%, -60%);
            transform: translate(-80%, -60%);
}

.Navbar_link__3Vph2 {
    display: block !important;
    margin: 45px 0 !important;
    background: transparent !important;
    font-size: 41px !important;
    padding: 0px !important;
    font-family: "Montserrat", sans-serif !important;
    border: none;
    color: white;
}

/* @media only screen and (max-width: 991px) {
    .mobileNav {
        display: initial;
    }
    .navLinksdiv {
        transform: translate(-60%, -50%);
    }
} */

.myProjects_topDiv__FCHtE {
    background-color: white;
    color: black;
    text-align: center;
}

.myProjects_Heading__332dK {
    font-weight: 500;
    font-size: 4rem;
    padding-top: 50px;
}

.myProjects_miniHeading__3eYtI {
    font-size: 1.5rem;
}

.myProjectsCard_cardClass__3LX9f {
    margin: 20px;
}

.myProjectsCard_cardMainSection__1ZRmi {
    float: left;
    width: 96%;
    background-color: white;
    color: white;
    z-index: -100;
    opacity: 0;
    position: relative;
    height: 13.5rem;
    background-color: rgba(0, 0, 0, 0.55);
    background-blend-mode: multiply;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.63);
    z-index: 1;
    overflow: hidden;
    margin: 3% 1% 3% 1%;
}

.myProjectsCard_cardNumber__287HQ {
    position: absolute;
    right: 1rem;
    top: 0;
    color: #fff;
    font-weight: 500;
    font-size: 2.25rem;
    font-size: clamp(2.75rem, 6.25vw, 4rem);
}

.myProjectsCard_cardContent__1cRVY {
    position: absolute;
    bottom: 0;
    padding: 0 0 1rem 1rem;
    text-align: start;
}

.myProjectsCard_cardContentHeading__2ciVw {
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    font-size: clamp(1rem, 6.25vw, 1.4rem);
    text-align: left;
}

.myProjectsCard_cardContentDescription__2PhHI {
    color: hsla(0, 0%, 100%, 0.9);
    font-weight: 400;
    font-size: 1rem;
    font-size: clamp(1rem, 6.25vw, 1.075rem);
}

.myProjectsCard_cardContentButtons__1SODY {
    display: -webkit-flex;
    display: flex;
    margin-top: 0.65rem;
}

.myProjectsCard_cardContentButton__2935i {
    margin-right: 0.5rem;
    padding: 0.3rem 1.35rem;
    border-radius: 0.1rem;
    background: #fff;
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    cursor: pointer;
}

.myProjectsCard_cardContentButton2__1Fr_b {
    margin-right: 0.5rem;
    padding: 0.3rem 1.35rem;
    border-radius: 0.1rem;
    background: #DDDDDC;
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    cursor: pointer;
}

.myProjectsCard_cardContentButton__2935i:hover {
    background-color: transparent;
    color: white;
    border: 2px solid white;
}

.aboutMe_topDiv__3mktJ {
    background: url(/static/media/bg.9202fac8.jpg);
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.894);
    overflow: "hidden";
}

.aboutMe_aboutMeDiv__1uK0F {
    color: white;
    padding: 100px 30% 200px 50px;
    z-index: 1;
}

.aboutMe_heading__1w0US {
    font-size: clamp(3.25rem, 6.25vw, 5.95rem);
    margin-bottom: 20px;
}

.aboutMe_info__3P5Vi {
    font-size: clamp(1.15rem, 6.25vw, 1.2rem);
    margin-bottom: 20px;
}

.aboutMe_profileLink__2OD5L {
    float: left;
    margin: 0 2rem 0 0;
}

@media screen and (max-width: 800px) {
    .aboutMe_aboutMeDiv__1uK0F {
        padding: 100px 50px 200px 50px;
    }
}

.mySkills_topDiv__TBhJp {
    background-color: white;
    color: black;
    text-align: center;
}

.mySkills_Heading__1-p7E {
    font-weight: 500;
    font-size: 4rem;
    padding-top: 50px;
}

.mySkills_miniHeading__Azb-E {
    font-size: 1.5rem;
    margin-bottom: 4rem;
}

.testimonials_topDiv__27OMS {
    background: url(/static/media/bg.9202fac8.jpg);
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.894);
    overflow: "hidden";
    color: white;
    padding: 100px 30% 100px 50px;
    z-index: 1;
}

.testimonials_heading__1AFm3 {
    font-size: clamp(3rem, 6.25vw, 5.95rem);
    margin-bottom: 20px;
}

.testimonials_content__2GBfg {
    font-size: clamp(1.15rem, 6.25vw, 1.2rem);
    margin-bottom: 20px;
    width: 70%;
}

.testimonials_TestimonialCardText__1BtKx {
    text-align: start;
}

@media screen and (max-width: 800px) {
    .testimonials_topDiv__27OMS {
        padding: 100px 50px 50px 50px;
    }
    .testimonials_content__2GBfg {
        width: 100%;
    }

    .testimonials_TestimonialCardText__1BtKx {
        text-align: center;
    }

    .testimonials_TestimonialCardLine__3NhZU {
        margin: auto;
    }
}

.getInTouch_topDiv__3V7nA {
    background-color: white;
    color: black;
    text-align: center;
}

.getInTouch_heading__3aol7 {
    font-weight: 500;
    font-size: clamp(2.25rem, 4rem, 4rem);
    padding-top: 50px;
    line-height: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.getInTouch_miniHeading__1zPyI {
    font-size: 1.5rem;
}

.getInTouch_content__1C9MP {
    padding-top: 30px;
    padding-bottom: 70px;
}

.getInTouch_form__3KFyE {
    display: grid;
    grid-column: 1fr;
}

.getInTouch_inputField__2knTZ {
    float: left;
    margin: 20px auto;
    text-align: start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
}

input,
textarea {
    width: 200px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid black;
}

@media (min-width: 320px) {
    input,
    textarea {
        width: 300px;
    }
}

@media (min-width: 500px) {
    input,
    textarea {
        width: 480px;
    }
}

@media (min-width: 650px) {
    input,
    textarea {
        width: 600px;
    }
}

.getInTouch_inputField__2knTZ:focus > input {
    outline: none;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid #e22837;
}

input:focus,
textarea:focus {
    outline: none;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid #e22837;
}

input,
textarea,
label {
    display: block;
}

input,
textarea {
    margin: 0 auto;
}

label {
    margin-bottom: -50px;
    z-index: 9;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

input:focus + label {
    color: #e22837;
    font-weight: 600;
    margin-bottom: -15px;
    transition: all 0.2s ease-in-out;
}

textarea:focus + label {
    color: #e22837;
    font-weight: 600;
    margin-bottom: 0;
    transition: all 0.2s ease-in-out;
}

input {
    height: 50px;
}

input:valid + label {
    margin-bottom: -15px;
}

textarea:valid + label {
    margin-bottom: 0;
}

.getInTouch_buttons__2H3wI {
    margin: 10px auto;
}

.getInTouch_button__3xUru {
    padding: 0.3rem 1.35rem;
    border-radius: 0.2rem;
    background: #e22837;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-size: medium;
}

.getInTouch_button__3xUru:hover {
    background-color: transparent;
    color: #e22837;
    border: 2px solid #e22837;
}

.footer_topDiv__3mhNi {
    background: url(/static/media/bg.9202fac8.jpg);
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.894);
    text-align: center;
    margin-bottom: 0;
}

.footer_footerLinks__t1B-6 {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    overflow: hidden;
}

.footer_footerLink__DpQkI {
    float: left;
    margin: 50px 50px;
}

.footer_link1__27LDm {
    text-align: end;
    margin-right: 0;
}

.footer_link3__1uF8y {
    text-align: start;
    margin-left: 0;
}

.footer_footerText__10Xpf {
    color: white;
    padding-bottom: 30px;
}

.loader_mainDiv__u4tL7 {
    background: black;
    height: 100vh;
}

.loader_box1__1xh8m {
    background-color: #e22837;
    height: 100vh;
    width: 34%;
    float: left;
}
.loader_box2__1rp5O {
    background-color: #e22837;
    height: 100vh;
    width: 33%;
    float: left;
}

.loader_box4__3WWTZ {
    background: url(/static/media/bg.9202fac8.jpg);
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.894);
    height: 100vh;
    width: 100%;
    float: left;
    position: absolute;
}

@media screen and (max-width: 1280px) {
    .loader_box1__1xh8m {
        width: 100%;
    }
    .loader_box2__1rp5O {
        width: 0%;
    }
    .loader_box3__2NMpf {
        width: 0%;
    }
}

