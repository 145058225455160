.topDiv {
    background: url("../images/bg.jpg");
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.894);
    overflow: "hidden";
    color: white;
    padding: 100px 30% 100px 50px;
    z-index: 1;
}

.heading {
    font-size: clamp(3rem, 6.25vw, 5.95rem);
    margin-bottom: 20px;
}

.content {
    font-size: clamp(1.15rem, 6.25vw, 1.2rem);
    margin-bottom: 20px;
    width: 70%;
}

.TestimonialCardText {
    text-align: start;
}

@media screen and (max-width: 800px) {
    .topDiv {
        padding: 100px 50px 50px 50px;
    }
    .content {
        width: 100%;
    }

    .TestimonialCardText {
        text-align: center;
    }

    .TestimonialCardLine {
        margin: auto;
    }
}
