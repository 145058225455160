.mainDiv {
    background: black;
    height: 100vh;
}

.box1 {
    background-color: #e22837;
    height: 100vh;
    width: 34%;
    float: left;
}
.box2 {
    background-color: #e22837;
    height: 100vh;
    width: 33%;
    float: left;
}

.box4 {
    background: url("../../images/bg.jpg");
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.894);
    height: 100vh;
    width: 100%;
    float: left;
    position: absolute;
}

@media screen and (max-width: 1280px) {
    .box1 {
        width: 100%;
    }
    .box2 {
        width: 0%;
    }
    .box3 {
        width: 0%;
    }
}
