.cardClass {
    margin: 20px;
}

.cardMainSection {
    float: left;
    width: 96%;
    background-color: white;
    color: white;
    z-index: -100;
    opacity: 0;
    position: relative;
    height: 13.5rem;
    background-color: rgba(0, 0, 0, 0.55);
    background-blend-mode: multiply;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.63);
    z-index: 1;
    overflow: hidden;
    margin: 3% 1% 3% 1%;
}

.cardNumber {
    position: absolute;
    right: 1rem;
    top: 0;
    color: #fff;
    font-weight: 500;
    font-size: 2.25rem;
    font-size: clamp(2.75rem, 6.25vw, 4rem);
}

.cardContent {
    position: absolute;
    bottom: 0;
    padding: 0 0 1rem 1rem;
    text-align: start;
}

.cardContentHeading {
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    font-size: clamp(1rem, 6.25vw, 1.4rem);
    text-align: left;
}

.cardContentDescription {
    color: hsla(0, 0%, 100%, 0.9);
    font-weight: 400;
    font-size: 1rem;
    font-size: clamp(1rem, 6.25vw, 1.075rem);
}

.cardContentButtons {
    display: flex;
    margin-top: 0.65rem;
}

.cardContentButton {
    margin-right: 0.5rem;
    padding: 0.3rem 1.35rem;
    border-radius: 0.1rem;
    background: #fff;
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    cursor: pointer;
}

.cardContentButton2 {
    margin-right: 0.5rem;
    padding: 0.3rem 1.35rem;
    border-radius: 0.1rem;
    background: #DDDDDC;
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    cursor: pointer;
}

.cardContentButton:hover {
    background-color: transparent;
    color: white;
    border: 2px solid white;
}
