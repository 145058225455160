.topDiv {
    background: url("../images/bg.jpg");
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.894);
    text-align: center;
    margin-bottom: 0;
}

.footerLinks {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    overflow: hidden;
}

.footerLink {
    float: left;
    margin: 50px 50px;
}

.link1 {
    text-align: end;
    margin-right: 0;
}

.link3 {
    text-align: start;
    margin-left: 0;
}

.footerText {
    color: white;
    padding-bottom: 30px;
}
