.topDiv {
    background-color: white;
    color: black;
    text-align: center;
}

.heading {
    font-weight: 500;
    font-size: clamp(2.25rem, 4rem, 4rem);
    padding-top: 50px;
    line-height: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.miniHeading {
    font-size: 1.5rem;
}

.content {
    padding-top: 30px;
    padding-bottom: 70px;
}

.form {
    display: grid;
    grid-column: 1fr;
}

.inputField {
    float: left;
    margin: 20px auto;
    text-align: start;
    display: flex;
    flex-direction: column-reverse;
}

input,
textarea {
    width: 200px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid black;
}

@media (min-width: 320px) {
    input,
    textarea {
        width: 300px;
    }
}

@media (min-width: 500px) {
    input,
    textarea {
        width: 480px;
    }
}

@media (min-width: 650px) {
    input,
    textarea {
        width: 600px;
    }
}

.inputField:focus > input {
    outline: none;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid #e22837;
}

input:focus,
textarea:focus {
    outline: none;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid #e22837;
}

input,
textarea,
label {
    display: block;
}

input,
textarea {
    margin: 0 auto;
}

label {
    margin-bottom: -50px;
    z-index: 9;
    width: fit-content;
}

input:focus + label {
    color: #e22837;
    font-weight: 600;
    margin-bottom: -15px;
    transition: all 0.2s ease-in-out;
}

textarea:focus + label {
    color: #e22837;
    font-weight: 600;
    margin-bottom: 0;
    transition: all 0.2s ease-in-out;
}

input {
    height: 50px;
}

input:valid + label {
    margin-bottom: -15px;
}

textarea:valid + label {
    margin-bottom: 0;
}

.buttons {
    margin: 10px auto;
}

.button {
    padding: 0.3rem 1.35rem;
    border-radius: 0.2rem;
    background: #e22837;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-size: medium;
}

.button:hover {
    background-color: transparent;
    color: #e22837;
    border: 2px solid #e22837;
}
