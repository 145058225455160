@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");

.mobileNav {
    /* display: none; */
    display: initial;
}

.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    background: #141313;
    clip-path: circle(5px at calc(100% - 45px) 45px);
    transition: all 0.3s ease-in-out;
}
.active:checked ~ .wrapper {
    clip-path: circle(75%);
    z-index: 10;
}
.menubtn {
    position: relative;
    z-index: 15;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    font-size: 40px;
    color: #fff;
    cursor: pointer;
    /* background-color: #141313; */
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;
}
.active:checked ~ .menubtn {
    /* background: #fff; */
    color: #d6372e;
    z-index: 100;
}
.active:checked ~ .menubtn i:before {
    /* font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 40px; */
    content: "\f00d";
    z-index: 10;
}
.wrapper ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    padding: 0;
}
.wrapper ul li {
    margin: 15px 0;
}
.wrapper ul li a {
    color: none;
    text-decoration: none;
    font-size: 30px;
    font-weight: 500;
    padding: 5px 30px;
    color: #fff;
    position: relative;
    line-height: 50px;
    transition: all 0.3s ease;
}
.wrapper ul li a:after {
    position: absolute;
    content: "";
    background: #fff;
    width: 100%;
    height: 50px;
    left: 0;
    border-radius: 50px;
    transform: scaleY(0);
    z-index: 1;
    transition: transform 0.4s ease;
}
.wrapper ul li a:hover:after {
    transform: scaleY(1);
}
.wrapper ul li a:hover {
    color: #4158d0;
}

.afterdot {
    font-size: 1.8em;
    background-clip: content-box;
    font-style: normal;
    background: linear-gradient(45deg, #e22837, #df3d7e);
    background: -webkit-linear-gradient(45deg, #e22837, #df3d7e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 2px;
}

.navLinksdiv {
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 30px;
    transform: translate(-80%, -60%);
}

.link {
    display: block !important;
    margin: 45px 0 !important;
    background: transparent !important;
    font-size: 41px !important;
    padding: 0px !important;
    font-family: "Montserrat", sans-serif !important;
    border: none;
    color: white;
}

/* @media only screen and (max-width: 991px) {
    .mobileNav {
        display: initial;
    }
    .navLinksdiv {
        transform: translate(-60%, -50%);
    }
} */
