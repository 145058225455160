.topDiv {
    background: url("../images/bg.jpg");
    background-position: top;
    background-repeat: no-repeat;
    /* background-size: cover; */
    position: relative;
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.894);
    color: white;
    height: 100vh;
}

.topName {
    font-size: 1.8rem;
    font-weight: 400;
    position: relative;
    animation: moveTopName 1s 1;
    animation-delay: 0;
    animation-fill-mode: forwards;
    opacity: 0;
    float: left;
}

@keyframes moveTopName {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.NavIcon {
    float: right;
}

.landingPageNameDiv {
    flex: 16;
}

.div1 {
    font-size: clamp(1.7rem, 6.25vw, 2rem);
    position: relative;
    animation: moveDiv1 1s 1;
    animation-delay: 1.7s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes moveDiv1 {
    from {
        left: 20px;
        opacity: 0;
    }
    to {
        left: 0px;
        opacity: 1;
    }
}

.div2 {
    font-size: clamp(2.5rem, 5vw, 10rem);
    font-weight: 500;
    position: relative;
    animation: moveDiv2 1s 1;
    animation-delay: 0.7s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes moveDiv2 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.div3 {
    font-size: clamp(1.7rem, 6.25vw, 2rem);
    position: relative;
    animation: moveDiv3 1s 1;
    animation-delay: 1.7s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes moveDiv3 {
    from {
        right: 20px;
        opacity: 0;
    }
    to {
        right: 0px;
        opacity: 1;
    }
}

.profileButton {
    float: none;
    margin: 2rem 0;
}

.moveProfileButton1 {
    position: relative;
    animation: moveProfileButtons 0.5s 1;
    animation-delay: 2.7s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.moveProfileButton2 {
    position: relative;
    animation: moveProfileButtons 0.5s 1;
    animation-delay: 2.8s;
    animation-fill-mode: forwards;
    opacity: 0;
}
.moveProfileButton3 {
    position: relative;
    animation: moveProfileButtons 0.5s 1;
    animation-delay: 2.9s;
    animation-fill-mode: forwards;
    opacity: 0;
}
.moveProfileButton4 {
    position: relative;
    animation: moveProfileButtons 0.5s 1;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes moveProfileButtons {
    from {
        left: 10px;
        opacity: 0;
    }
    to {
        left: 0px;
        opacity: 1;
    }
}

@media screen and (max-width: 1280px) {
    .profileButton {
        float: left;
        margin: 0 2rem 0 0;
    }
}
